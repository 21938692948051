// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as React from "react";
import * as Button from "../../components/Button/Button.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Dropdown from "../../forms/Dropdown/Dropdown.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Textarea from "../../forms/Textarea/Textarea.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NoBidModalScss from "../NoBidModal/NoBidModal.scss";

var css = NoBidModalScss;

function reducer(state, action) {
  switch (action.TAG) {
    case "FetchProviders" :
        var res = action._0;
        var match = res.providers;
        var providerId;
        if (match.length !== 1) {
          providerId = undefined;
        } else {
          var provider = match[0];
          providerId = Caml_option.some(provider.id);
        }
        var input = state.input;
        return {
                input: {
                  message: input.message,
                  providerId: providerId
                },
                providers: res.providers,
                fetchProviderApiStatus: "Success",
                preparingProposalApiStatus: state.preparingProposalApiStatus
              };
    case "SetFetchProviderApiStatus" :
        return {
                input: state.input,
                providers: state.providers,
                fetchProviderApiStatus: action._0,
                preparingProposalApiStatus: state.preparingProposalApiStatus
              };
    case "Update" :
        return {
                input: action._0,
                providers: state.providers,
                fetchProviderApiStatus: state.fetchProviderApiStatus,
                preparingProposalApiStatus: state.preparingProposalApiStatus
              };
    case "SetPreparingProposalApiStatus" :
        return {
                input: state.input,
                providers: state.providers,
                fetchProviderApiStatus: state.fetchProviderApiStatus,
                preparingProposalApiStatus: action._0
              };
    
  }
}

function PreparingProposalModal(props) {
  var projectId = props.projectId;
  var close = props.close;
  var match = React.useReducer(reducer, {
        input: Provider.Simple.NoBidInput.empty(),
        providers: [],
        fetchProviderApiStatus: "Success",
        preparingProposalApiStatus: "Success"
      });
  var dispatch = match[1];
  var state = match[0];
  var fetchProviders = function () {
    dispatch({
          TAG: "SetFetchProviderApiStatus",
          _0: "Fetching"
        });
    $$Promise.wait(Api.fetchProviders(projectId), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "FetchProviders",
                          _0: x._0
                        });
            }
            SentryLogger.error1({
                  rootModule: "PreparingProposalModal",
                  subModulePath: /* [] */0,
                  value: "make",
                  fullPath: "PreparingProposalModal.make"
                }, "PreparingProposalModal::FetchProviders::Error", [
                  "Error",
                  x._0
                ]);
            dispatch({
                  TAG: "SetFetchProviderApiStatus",
                  _0: "Error"
                });
          }));
  };
  var sendSetPreparingStatus = function () {
    $$Promise.wait(Api.setStatusOfProposalToPreparingByProvider(projectId, state.input), (function (x) {
            if (x.TAG === "Ok") {
              window.location.reload();
              return ;
            }
            SentryLogger.error1({
                  rootModule: "PreparingProposalModal",
                  subModulePath: /* [] */0,
                  value: "make",
                  fullPath: "PreparingProposalModal.make"
                }, "PreparingProposalModal::PreparingProposalStatus::Error", [
                  "Error",
                  x._0
                ]);
            dispatch({
                  TAG: "SetPreparingProposalApiStatus",
                  _0: "Error"
                });
          }));
  };
  React.useEffect((function () {
          fetchProviders();
        }), []);
  var match$1 = state.fetchProviderApiStatus;
  var tmp;
  if (match$1 === "Error") {
    tmp = JsxRuntime.jsx("p", {
          children: "Error on fetching providers. Please try again later."
        });
  } else if (match$1 === "Fetching") {
    tmp = JsxRuntime.jsx("p", {
          children: "Fetching providers. Please wait..."
        });
  } else {
    var match$2 = state.providers;
    if (match$2.length !== 0) {
      var exit = 0;
      switch (props.userRole) {
        case "Provider" :
            tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                  children: [
                    JsxRuntime.jsx("p", {
                          children: "Inform a project manager that you are working on the proposal.",
                          className: css.description
                        }),
                    JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("label", {
                                  children: "Message to the Client",
                                  className: css.label
                                }),
                            JsxRuntime.jsx(Textarea.make, {
                                  id: "preparing-proposal--message",
                                  value: state.input.message,
                                  rows: 4,
                                  placeholder: "Describe the reason",
                                  className: css.message,
                                  onChange: (function ($$event) {
                                      var init = state.input;
                                      dispatch({
                                            TAG: "Update",
                                            _0: {
                                              message: $$event.target.value,
                                              providerId: init.providerId
                                            }
                                          });
                                    })
                                })
                          ],
                          className: css.field
                        })
                  ]
                });
            break;
        case "Admin" :
        case "Concierge" :
            exit = 1;
            break;
        default:
          tmp = null;
      }
      if (exit === 1) {
        var id = state.input.providerId;
        var tmp$1;
        if (id !== undefined) {
          var id$1 = Caml_option.valFromOption(id);
          tmp$1 = Belt_Array.map(state.providers, (function (provider) {
                  if (Caml_obj.equal(provider.id, id$1)) {
                    return JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("img", {
                                        className: css.providerLogo,
                                        src: provider.logo
                                      }),
                                  JsxRuntime.jsx("label", {
                                        children: provider.name,
                                        className: css.option
                                      })
                                ],
                                className: css.logoNamePair
                              });
                  } else {
                    return null;
                  }
                }));
        } else {
          tmp$1 = "Select a Provider";
        }
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("p", {
                      children: "Select the provider for which you want to set preparing status and click the button to confirm.",
                      className: css.description
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("label", {
                              children: "Provider",
                              className: css.label
                            }),
                        JsxRuntime.jsxs(Dropdown.make, {
                              children: [
                                JsxRuntime.jsx(Dropdown.Trigger.make, {
                                      className: css.dropdownTrigger,
                                      iconSize: "XS",
                                      iconColor: "Gray",
                                      children: tmp$1
                                    }),
                                JsxRuntime.jsx(Dropdown.Body.make, {
                                      position: {
                                        TAG: "Below",
                                        _0: "LeftEdge"
                                      },
                                      className: css.dropdownBody,
                                      children: Belt_Array.map(state.providers, (function (provider) {
                                              var id = "no-bid--provider-" + ID.toString(provider.id);
                                              return JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsxs(Control.make, {
                                                                className: css.logoNamePair,
                                                                onClick: (function (param) {
                                                                    var init = state.input;
                                                                    dispatch({
                                                                          TAG: "Update",
                                                                          _0: {
                                                                            message: init.message,
                                                                            providerId: Caml_option.some(provider.id)
                                                                          }
                                                                        });
                                                                  }),
                                                                children: [
                                                                  JsxRuntime.jsx("img", {
                                                                        className: css.providerLogo,
                                                                        src: provider.logo
                                                                      }),
                                                                  JsxRuntime.jsx("label", {
                                                                        children: provider.name,
                                                                        className: css.option
                                                                      })
                                                                ]
                                                              }),
                                                          className: css.dropdownBodyRow
                                                        }, id);
                                            }))
                                    })
                              ]
                            })
                      ],
                      className: css.field
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("label", {
                              children: "Message to the Client",
                              className: css.label
                            }),
                        JsxRuntime.jsx(Textarea.make, {
                              id: "no-bid--message",
                              value: state.input.message,
                              rows: 4,
                              placeholder: "Describe the reason",
                              className: css.message,
                              onChange: (function ($$event) {
                                  var init = state.input;
                                  dispatch({
                                        TAG: "Update",
                                        _0: {
                                          message: $$event.target.value,
                                          providerId: init.providerId
                                        }
                                      });
                                })
                            })
                      ],
                      className: css.field
                    })
              ]
            });
      }
      
    } else {
      tmp = JsxRuntime.jsx("p", {
            children: "No providers found"
          });
    }
  }
  var match$3 = state.preparingProposalApiStatus;
  var match$4 = state.providers.length;
  var tmp$2;
  if (match$4 !== 0) {
    if (match$4 !== 1) {
      var match$5 = state.input.message;
      var tmp$3 = match$5 === "" ? true : false;
      var match$6 = state.input.message;
      var match$7 = state.input.providerId;
      var tmp$4 = match$6 === "" ? true : match$7 === undefined;
      tmp$2 = JsxRuntime.jsx(Button.make, {
            size: "SM",
            color: "Teal",
            disabled: tmp$3,
            visuallyDisabled: tmp$4,
            className: css.sendButton,
            onClick: (function (param) {
                sendSetPreparingStatus();
              }),
            children: "Set Status"
          });
    } else {
      tmp$2 = JsxRuntime.jsx(Button.make, {
            size: "SM",
            color: "Teal",
            disabled: state.input.message === "",
            visuallyDisabled: state.input.message === "",
            className: css.sendButton,
            onClick: (function (param) {
                var uniqueProvider = Belt_Array.get(state.providers, 0);
                if (uniqueProvider === undefined) {
                  return ;
                }
                var init = state.input;
                dispatch({
                      TAG: "Update",
                      _0: {
                        message: init.message,
                        providerId: Caml_option.some(uniqueProvider.id)
                      }
                    });
                sendSetPreparingStatus();
              }),
            children: "Set Status"
          });
    }
  } else {
    tmp$2 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.modalOverlay,
                      onClick: (function (param) {
                          close();
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Control.make, {
                                    className: css.closeOverlayButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: JsxRuntime.jsx(IconClose.make, {
                                          title: "Close",
                                          size: "MD",
                                          color: "Gray"
                                        })
                                  }),
                              JsxRuntime.jsx("h2", {
                                    children: props.title
                                  }),
                              tmp,
                              match$3 === "Error" ? JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("p", {
                                                  children: "Error in setting Proposal Status. Please try again later."
                                                }),
                                            className: css.errors
                                          }),
                                      className: css.errorContainer
                                    }) : null,
                              JsxRuntime.jsx("div", {
                                    children: tmp$2,
                                    className: css.buttons
                                  })
                            ],
                            className: css.modalContents
                          }),
                      className: css.modal
                    })
              ],
              className: css.modalContainer
            });
}

var make = PreparingProposalModal;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
