// extracted by mini-css-extract-plugin
export var activeProposals = "UserProjects_List_Controls__activeProposals__faN0v";
export var buttonsContainer = "UserProjects_List_Controls__buttonsContainer__ji74b";
export var column = "UserProjects_List_Controls__column__FkubY";
export var deleteButton = "UserProjects_List_Controls__deleteButton__squn2";
export var draftButtonsContainer = "UserProjects_List_Controls__draftButtonsContainer__Bi6En";
export var fakeLink = "UserProjects_List_Controls__fakeLink__fSc_4";
export var largeHeight = "UserProjects_List_Controls__largeHeight__H72PJ";
export var link = "UserProjects_List_Controls__link__rgKE6";
export var modalMessageButtons = "UserProjects_List_Controls__modalMessageButtons__CXBHP";
export var modalMessageContainer = "UserProjects_List_Controls__modalMessageContainer__MGJzL";
export var modalMessageOverlay = "UserProjects_List_Controls__modalMessageOverlay___0CvW";
export var modalMessageText = "UserProjects_List_Controls__modalMessageText__BsHVc";
export var pillTitle = "UserProjects_List_Controls__pillTitle__qjVxk";
export var popoverBody = "UserProjects_List_Controls__popoverBody__FbplC";
export var popoverBodyActions = "UserProjects_List_Controls__popoverBodyActions__m5YxS";
export var popoverBodyActionsAdmin = "UserProjects_List_Controls__popoverBodyActionsAdmin__ZjkPQ";
export var popoverBodyPositionAdmin = "UserProjects_List_Controls__popoverBodyPositionAdmin__jQlA1";
export var popoverButton = "UserProjects_List_Controls__popoverButton__Ht0VO";
export var popoverIconActions = "UserProjects_List_Controls__popoverIconActions__syenn";
export var popoverProjectActions = "UserProjects_List_Controls__popoverProjectActions__J8eQv";
export var popoverRow = "UserProjects_List_Controls__popoverRow__Z2avR";
export var projectActions = "UserProjects_List_Controls__projectActions__SIlDX";
export var projectLabel = "UserProjects_List_Controls__projectLabel__A20XE";
export var projectLocationAndProviders = "UserProjects_List_Controls__projectLocationAndProviders__spe8m";
export var projectLocations = "UserProjects_List_Controls__projectLocations__PvamY";
export var projectProposalDates = "UserProjects_List_Controls__projectProposalDates__BzJNl";
export var projectProviderAdminControls = "UserProjects_List_Controls__projectProviderAdminControls__omsDP";
export var projectProviderLogo = "UserProjects_List_Controls__projectProviderLogo__m3UXr";
export var projectStatusBadge = "UserProjects_List_Controls__projectStatusBadge___yn5n";
export var projectStatusControl = "UserProjects_List_Controls__projectStatusControl__uhB1u";
export var projectStatusFinalized = "UserProjects_List_Controls__projectStatusFinalized__ygI95";
export var projectStatusInactive = "UserProjects_List_Controls__projectStatusInactive__nGCRL";
export var projectUserControls = "UserProjects_List_Controls__projectUserControls__aQLGk";
export var proposalDate = "UserProjects_List_Controls__proposalDate__vwHXz";
export var proposalDateContainer = "UserProjects_List_Controls__proposalDateContainer__f8GN6";
export var proposalDropdownTrigger = "UserProjects_List_Controls__proposalDropdownTrigger__OtnM2";
export var proposalLabel = "UserProjects_List_Controls__proposalLabel__EAJjl";
export var proposalStatus = "UserProjects_List_Controls__proposalStatus__Bj6Nr";
export var proposalsButton = "UserProjects_List_Controls__proposalsButton__CAQJ1";
export var providerDropdown = "UserProjects_List_Controls__providerDropdown__kbTmW";
export var row = "UserProjects_List_Controls__row__RsQHF";
export var smallHeight = "UserProjects_List_Controls__smallHeight__qF1om";
export var sortSelection = "UserProjects_List_Controls__sortSelection__pUZGw";
export var status = "UserProjects_List_Controls__status__yTarI";