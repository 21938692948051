// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Badge from "../../../../styleguide/components/Badge/Badge.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Cloudinary from "../../../../libs/Cloudinary.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as IconUserAvatar from "../../../../styleguide/icons/IconUserAvatar.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjects_List_ItemScss from "./UserProjects_List_Item.scss";
import * as UserProjects_List_ControlsForUser from "./UserProjects_List_ControlsForUser.res.js";
import * as UserProjects_List_ControlsForAdmin from "./UserProjects_List_ControlsForAdmin.res.js";
import * as UserProjects_List_ControlsForProvider from "./UserProjects_List_ControlsForProvider.res.js";

var css = UserProjects_List_ItemScss;

function date(date$1) {
  return Format(date$1, "dd MMM yyyy");
}

function imageUrl(publicId) {
  return Cloudinary.Url.build("Auto", {
              hd: "Dpr",
              tl: {
                hd: "Quality",
                tl: {
                  hd: {
                    TAG: "Crop",
                    _0: "Fill"
                  },
                  tl: {
                    hd: {
                      TAG: "Width",
                      _0: 200
                    },
                    tl: {
                      hd: {
                        TAG: "Height",
                        _0: 200
                      },
                      tl: /* [] */0
                    }
                  }
                }
              }
            }, Cloudinary.imageUrl(publicId));
}

var Helpers = {
  date: date,
  imageUrl: imageUrl
};

function UserProjects_List_Item(props) {
  var userViewContext = props.userViewContext;
  var userRole = props.userRole;
  var project = props.project;
  var category = React.useMemo((function () {
          return project.category;
        }), [project]);
  var projectUrl = React.useMemo((function () {
          if (project.TAG === "Draft") {
            return project.data.redirectUrl;
          } else {
            return Routes_Project.show(project.id);
          }
        }), [project]);
  var defaultTitle = React.useMemo((function () {
          switch (category.TAG) {
            case "Colocation" :
                return "Colocation RFP Project";
            case "Cloud" :
                if (category._0 === "IaasServer") {
                  return "Cloud IaaS Server Project";
                } else {
                  return "Cloud IaaS Storage Project";
                }
            case "Internet" :
                return "Internet RFP Project";
            case "Network" :
                switch (category._0) {
                  case "SdWan" :
                      return "Network SD-WAN Project";
                  case "PrivateLine" :
                      return "Network Private Line Project";
                  case "Mpls" :
                      return "Network MPLS Project";
                  
                }
            case "BareMetal" :
                return "Bare Metal Project";
            
          }
        }), [category]);
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDeleteModalVisibility = match[1];
  var tmp;
  if (project.TAG === "Draft") {
    tmp = JsxRuntime.jsx("div", {
          children: "This project is still being drafted. Click title to continue editing.",
          className: css.draftMessage
        });
  } else {
    switch (project.data.status) {
      case "Draft" :
      case "Active" :
          tmp = null;
          break;
      case "Inactive" :
          tmp = JsxRuntime.jsx("div", {
                children: "This project is inactive.",
                className: css.inactiveMessage
              });
          break;
      case "Finalized" :
          tmp = JsxRuntime.jsx("div", {
                children: "This project has been finalized.",
                className: css.finalizedMessage
              });
          break;
      case "Review" :
          tmp = JsxRuntime.jsx("div", {
                children: "This project is under review.",
                className: css.reviewMessage
              });
          break;
      
    }
  }
  var tmp$1;
  switch (category.TAG) {
    case "Colocation" :
        tmp$1 = JsxRuntime.jsx(Badge.make, {
              badgeType: "Colocation",
              className: css.productTag,
              children: "Colocation"
            });
        break;
    case "Cloud" :
        tmp$1 = category._0 === "IaasServer" ? JsxRuntime.jsx(Badge.make, {
                badgeType: "Cloud",
                className: css.productTag,
                children: "Cloud IaaS Server"
              }) : JsxRuntime.jsx(Badge.make, {
                badgeType: "Cloud",
                className: css.productTag,
                children: "Cloud IaaS Storage"
              });
        break;
    case "Internet" :
        tmp$1 = JsxRuntime.jsx(Badge.make, {
              badgeType: "Internet",
              className: css.productTag,
              children: "Internet"
            });
        break;
    case "Network" :
        switch (category._0) {
          case "SdWan" :
              tmp$1 = JsxRuntime.jsx(Badge.make, {
                    badgeType: "Network",
                    className: css.productTag,
                    children: "Network SD-WAN"
                  });
              break;
          case "PrivateLine" :
              tmp$1 = JsxRuntime.jsx(Badge.make, {
                    badgeType: "Network",
                    className: css.productTag,
                    children: "Network Private Line"
                  });
              break;
          case "Mpls" :
              tmp$1 = JsxRuntime.jsx(Badge.make, {
                    badgeType: "Network",
                    className: css.productTag,
                    children: "Network MPLS"
                  });
              break;
          
        }
        break;
    case "BareMetal" :
        tmp$1 = JsxRuntime.jsx(Badge.make, {
              badgeType: "BareMetal",
              className: css.productTag,
              children: "Bare Metal"
            });
        break;
    
  }
  var tmp$2;
  if (project.TAG === "Draft") {
    var match$1 = project.data;
    tmp$2 = Belt_Option.getWithDefault(match$1.title, "Draft: " + (defaultTitle + (" (" + (Format(match$1.startDate, "dd MMM yyyy") + ")"))));
  } else {
    var match$2 = project.data;
    tmp$2 = match$2.businessName + (": " + match$2.title);
  }
  var tmp$3;
  if (project.TAG === "Draft") {
    var match$3 = project.data;
    tmp$3 = "Created " + (Format(match$3.createdAt, "MMM dd, yyyy") + (" - " + Belt_Option.getWithDefault(match$3.description, "-")));
  } else {
    var match$4 = project.data;
    var createdAt = match$4.createdAt;
    var proposalDetails = match$4.proposalDetails;
    var description = match$4.description;
    if (proposalDetails !== undefined) {
      var match$5 = proposalDetails.status;
      tmp$3 = match$5 === "Preparing" ? proposalDetails.statusMessage : "Created " + (Format(createdAt, "MMM dd, yyyy") + (" - " + description));
    } else {
      tmp$3 = "Created " + (Format(createdAt, "MMM dd, yyyy") + (" - " + description));
    }
  }
  var tmp$4;
  var exit = 0;
  var startDate;
  var endDate;
  if (project.TAG === "Draft") {
    var match$6 = project.data;
    var endDate$1 = match$6.endDate;
    var startDate$1 = match$6.startDate;
    if (endDate$1 !== undefined) {
      startDate = startDate$1;
      endDate = Caml_option.valFromOption(endDate$1);
      exit = 1;
    } else {
      tmp$4 = Format(startDate$1, "dd MMM yyyy") + " - n/a";
    }
  } else {
    var match$7 = project.data;
    startDate = match$7.startDate;
    endDate = match$7.endDate;
    exit = 1;
  }
  if (exit === 1) {
    tmp$4 = Format(startDate, "dd MMM yyyy") + (" - " + Format(endDate, "dd MMM yyyy"));
  }
  var tmp$5;
  if (project.TAG === "Draft") {
    var redirectUrl = project.data.redirectUrl;
    var category$1 = project.category;
    var id = project.id;
    var tmp$6;
    switch (category$1.TAG) {
      case "Cloud" :
          tmp$6 = category$1._0 === "IaasServer" ? "Continue IaaS Server" : "Continue IaaS Storage";
          break;
      case "Colocation" :
      case "Internet" :
          tmp$6 = "Continue RFP";
          break;
      case "Network" :
          switch (category$1._0) {
            case "SdWan" :
                tmp$6 = "Continue SD-WAN";
                break;
            case "PrivateLine" :
                tmp$6 = "Continue Private Line";
                break;
            case "Mpls" :
                tmp$6 = "Continue MPLS";
                break;
            
          }
          break;
      case "BareMetal" :
          tmp$6 = "Continue Bare Metal";
          break;
      
    }
    tmp$5 = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(Button.make, {
                  size: "SM",
                  color: "Teal",
                  className: css.continueButton,
                  onClick: (function (param) {
                      Url.visit(redirectUrl);
                    }),
                  children: tmp$6
                }),
            JsxRuntime.jsx(Control.make, {
                  className: css.deleteButton,
                  onClick: (function (param) {
                      setDeleteModalVisibility(function (param) {
                            return "Shown";
                          });
                    }),
                  children: "Delete"
                }),
            match[0] === "Shown" ? JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("div", {
                                  children: "Are you sure?",
                                  className: css.modalMessageText
                                }),
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Button.make, {
                                          size: "SM",
                                          color: "Gray",
                                          onClick: (function (param) {
                                              setDeleteModalVisibility(function (param) {
                                                    return "Hidden";
                                                  });
                                            }),
                                          children: "Cancel"
                                        }),
                                    JsxRuntime.jsx(Button.make, {
                                          size: "SM",
                                          color: "Pink",
                                          onClick: (function (param) {
                                              $$Promise.wait(Api.deleteProject(id), (function (x) {
                                                      if (x.TAG !== "Ok") {
                                                        return SentryLogger.error({
                                                                    rootModule: "UserProjects_List_Item",
                                                                    subModulePath: /* [] */0,
                                                                    value: "make",
                                                                    fullPath: "UserProjects_List_Item.make"
                                                                  }, "DeleteProject::Error");
                                                      }
                                                      window.location.reload();
                                                    }));
                                            }),
                                          children: "Yes, delete it"
                                        })
                                  ],
                                  className: css.modalMessageButtons
                                })
                          ],
                          className: css.modalMessageContainer
                        }),
                    className: css.modalMessageOverlay
                  }) : null
          ],
          className: css.draftButtonsContainer
        });
  } else {
    tmp$5 = null;
  }
  var tmp$7;
  var exit$1 = 0;
  var contact;
  if (project.TAG === "Draft") {
    var contact$1 = project.data.contact;
    if (contact$1 !== undefined) {
      contact = contact$1;
      exit$1 = 1;
    } else {
      tmp$7 = null;
    }
  } else {
    var contact$2 = project.data.contact;
    if (contact$2 !== undefined) {
      contact = contact$2;
      exit$1 = 1;
    } else {
      tmp$7 = null;
    }
  }
  if (exit$1 === 1) {
    var image = contact.image;
    var tmp$8;
    tmp$8 = image !== undefined && image !== "" ? JsxRuntime.jsx("img", {
            src: imageUrl(image)
          }) : JsxRuntime.jsx(IconUserAvatar.make, {
            size: "XL"
          });
    var title = contact.jobTitle;
    tmp$7 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: tmp$8,
                  className: css.projectUserAvatar
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("p", {
                          children: contact.fullName
                        }),
                    JsxRuntime.jsx("span", {
                          children: (
                            title !== undefined ? title + " at " : ""
                          ) + contact.companyName
                        })
                  ],
                  className: css.projectUserText
                })
          ]
        });
  }
  var tmp$9;
  var exit$2 = 0;
  switch (userViewContext) {
    case "Personal" :
        tmp$9 = JsxRuntime.jsx(UserProjects_List_ControlsForUser.make, {
              project: project,
              updateProjectStatus: props.updateProjectStatus,
              userRole: userRole
            });
        break;
    case "Admin" :
    case "Visitor" :
        exit$2 = 1;
        break;
    
  }
  if (exit$2 === 1) {
    if (project.TAG === "Draft") {
      tmp$9 = null;
    } else {
      var projectData = project.data;
      var projectId = project.id;
      if (userViewContext === "Admin") {
        switch (userRole) {
          case "Provider" :
              tmp$9 = JsxRuntime.jsx(UserProjects_List_ControlsForProvider.make, {
                    projectId: projectId,
                    projectData: projectData,
                    userRole: userRole,
                    updateProposalStatus: props.updateProposalStatus,
                    userViewContext: userViewContext
                  });
              break;
          case "User" :
          case "Visitor" :
              tmp$9 = null;
              break;
          default:
            tmp$9 = JsxRuntime.jsx(UserProjects_List_ControlsForAdmin.make, {
                  projectId: projectId,
                  projectData: projectData,
                  userRole: userRole
                });
        }
      } else {
        tmp$9 = null;
      }
    }
  }
  var tmp$10;
  if (project.TAG === "Draft") {
    tmp$10 = css.draftProjectItem;
  } else {
    switch (project.data.status) {
      case "Draft" :
          tmp$10 = css.draftProjectItem;
          break;
      case "Active" :
          tmp$10 = "";
          break;
      case "Inactive" :
          tmp$10 = css.inactiveProjectItem;
          break;
      case "Finalized" :
          tmp$10 = css.finalizedProjectItem;
          break;
      case "Review" :
          tmp$10 = css.reviewProjectItem;
          break;
      
    }
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        tmp$1,
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(A.make, {
                                                    id: "projectLink",
                                                    href: projectUrl,
                                                    children: tmp$2
                                                  }),
                                              className: css.projectTitle
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$3,
                                              className: css.projectDescription
                                            })
                                      ],
                                      className: css.projectName
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Estimated project dates",
                                              className: css.projectLabel
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$4,
                                              className: css.projectDates
                                            }),
                                        tmp$5,
                                        JsxRuntime.jsx("div", {
                                              children: tmp$7,
                                              className: css.projectUser
                                            })
                                      ]
                                    })
                              ],
                              className: css.projectInfo
                            }),
                        tmp$9
                      ],
                      className: css.projectItemContainer
                    })
              ],
              className: Cx.cx([
                    css.projectItem,
                    tmp$10
                  ])
            });
}

var make = UserProjects_List_Item;

export {
  css ,
  Helpers ,
  make ,
}
/* css Not a pure module */
